/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};


/**
 * Description: JS for
 * Version: 1.0.0
 * Last update: 2015/05/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *	0. matchMedia() polyfill
 *		- Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *	1. Respond.js
 *		- min/max-width media query polyfill.
 *	2. Custom JS for
 *
 *  3. bxslider
 */

/* ==========================================================================
   0. matchMedia() polyfill
   ========================================================================== */

window.matchMedia=window.matchMedia||(function(e,f){var c,a=e.documentElement,b=a.firstElementChild||a.firstChild,d=e.createElement("body"),g=e.createElement("div");g.id="mq-test-1";g.style.cssText="position:absolute;top:-100em";d.style.background="none";d.appendChild(g);return function(h){g.innerHTML='&shy;<style media="'+h+'"> #mq-test-1 { width: 42px; }</style>';a.insertBefore(d,b);c=g.offsetWidth==42;a.removeChild(d);return{matches:c,media:h}}})(document);


/* ==========================================================================
   2. Custom JS for
   ========================================================================== */

$(function() {
    if ($.fn.uvoAutocomplete) {
        //add class "selected" for item select
        $('.place-your-order select').uvoAutocomplete({
            sortBy: 'none',
            onOpen: function(e) {
                var lebelEl = this.label;
                $(this._listElement.children()).each(function() {
                    if ($(this).text() == lebelEl) {
                        $(this).addClass('selected')
                    }
                });
            },
            sortBy: 'none'
        });
        //count pages
        $.fn.counts = function(options) {
            //defaultOptions
            options = $.extend({
                minusClass: 'pageskol-left',
                minimum: 1
            }, options);
            var inputCol = $(this);
            if ($(inputCol).val() <= options.minimum) {
                $('span[class=' + options.minusClass + ']').css({
                    'opacity': '0.5',
                    'cursor': 'default'
                });
            } else {
                $('span[class=' + options.minusClass + ']').css({
                    'opacity': '1',
                    'cursor': 'pointer'
                });
            }
            $(inputCol).parent().find('span').click(function() {
                if ($(this).hasClass(options.minusClass)) {
                    if ($(inputCol).val() >= options.minimum) {
                        var count = parseInt($(inputCol).val()) - 1;
                        count = count < options.minimum ? options.minimum : count;
                    } else {
                        $(inputCol).val(options.minimum - 1);
                        var count = parseInt($(inputCol).val()) - 1;
                        count = count < options.minimum ? options.minimum : count;
                    }
                    if ($(inputCol).val() <= (options.minimum + 1)) {
                        $('span[class=' + options.minusClass + ']').css({
                            'opacity': '0.5',
                            'cursor': 'default'
                        });
                    }
                } else {
                    if ($(inputCol).val() >= options.minimum) {
                        var count = parseInt($(inputCol).val()) + 1;
                    } else {
                        $(inputCol).val(options.minimum - 1);
                        var count = parseInt($(inputCol).val()) + 1;
                    }
                    if ($(inputCol).val() > (options.minimum - 1)) {
                        $('span[class=' + options.minusClass + ']').css({
                            'opacity': '1',
                            'cursor': 'pointer'
                        })
                    }
                }
                $(inputCol).val(count);
                $(inputCol).change();
                $(this).parent().next().find('span').html(275 * count);
                return false;
            });
            $(this).keydown(function(e) {
                if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e
                    .which != 96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
                    100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
                    104 && e.which != 105) {
                    return false;
                }
                var key, keyChar;
                if (!e) var e = window.e;
                if (e.keyCode) key = e.keyCode;
                else if (e.which) key = e.which;
                if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
                    96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
                    100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
                    104 && e.which != 105) {
                    if (key == 40 || key == 37) {
                        if ($(this).val() >= options.minimum) {
                            var count = parseInt($(this).val()) - 1;
                            count = count < options.minimum ? options.minimum : count;
                        } else {
                            $(this).val(options.minimum - 1);
                            keyChar = String.fromCharCode(key);
                            var count = parseInt($(this).val()) + 1;
                        }
                    } else {
                        if ($(this).val() >= options.minimum) {
                            var count = parseInt($(this).val()) + 1;
                        } else {
                            $(this).val(options.minimum - 1);
                            var count = parseInt($(this).val()) + 1;
                        }
                    }
                    $(this).val(count);
                    $(this).parent().next().find('span').html(275 * count);
                    if ($(inputCol).val() <= (options.minimum)) {
                        $('span[class=' + options.minusClass + ']').css({
                            'opacity': '0.5',
                            'cursor': 'default'
                        });
                    } else {
                        $('span[class=' + options.minusClass + ']').css({
                            'opacity': '1',
                            'cursor': 'pointer'
                        })
                    }
                }
            });
        }
        //counts
        $('#input-kol').counts();
    }
    //toplink click
    $('.totop-link').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    //toggle topmenu
    $('.toggle-menu').click(function() {
        $(this).toggleClass('active');
        //$('.toggle').hide();
        $('.toggle[id=' + $(this).attr('data-toggle') + ']').toggle();
        return false;
    });
    //for inquiry page
    $('.show-hide').find('.hidden').hide();
    $('#inq-done-more').click(function() {
        if (!$(this).hasClass('less')) {
            $(this).parents('.show-hide').find('.hidden').slideDown(
                500);
            $(this).text('Read less').addClass('less');
        } else {
            $(this).parents('.show-hide').find('.hidden').slideUp(
                500);
            $(this).text('Read more').removeClass('less');
        }
    });
    var pl_panel = $('.plagcheckc aside#rightplagpanel').clone();
    $(pl_panel).removeAttr('id').appendTo('.plagcheckc').attr('id',
        'rightplagpanel_2');
});



/* we-provide block btn for order */
$(document).ready(function(){
	$('[data-href]').click(function(){
		var dataHref = $(this).attr('data-href');
		var toTopheightCalc = $('.home-calc').offset().top;

		if(dataHref === 'acad_lvl_1'){
			$('html,body').animate({scrollTop: toTopheightCalc - 10 },1400,function(){
				$('#box_academic_level').addClass('animate-bg');
				//$('label.level-1').focus();
				$('label.level-1').click();
				setTimeout(function(){
					$('#box_academic_level').removeClass('animate-bg');
				},3500);
			});
		}
		if(dataHref === 'acad_lvl_2'){
			$('html,body').animate({scrollTop: toTopheightCalc - 10 },1400,function(){
				$('#box_academic_level').addClass('animate-bg');
				//$('label.level-1').focus();
				$('label.level-2').click();
				setTimeout(function(){
					$('#box_academic_level').removeClass('animate-bg');
				},3500);
			});
		}
		if(dataHref === 'acad_lvl_3'){
			$('html,body').animate({scrollTop: toTopheightCalc - 10 },1400,function(){
				$('#box_academic_level').addClass('animate-bg');
				//$('label.level-1').focus();
				$('label.level-3').click();
				setTimeout(function(){
					$('#box_academic_level').removeClass('animate-bg');
				},3500);
			});
		}
	});

  $.getScript('/assets/js/calc.js', function () {
 if ( $('[data-calc-mini]').length > 0 ) {
      new Calc({
        ID: '121',
        calculatorType: 'DefaultCalculator',
        format: 'json',
        environment: getEnvironment,
        options: {
          deafault: {
            deadlinesDefault: [138257],
            academicLevelDefault: 1,
            paperTypesDefault: 1,
            pagesDefault: 1,
          }
        }
      });
    }
    if ( $('[data-calc-short]').length > 0 ) {
    new Calc({
      ID: '121',
      calculatorType: 'ShortCalculator',
      format: 'json',
      environment: getEnvironment,
      options: {
        deafault: {
          deadlinesDefault: [138257],
          academicLevelDefault: 1,
          paperTypesDefault: 1,
          pagesDefault: 1,
        }
      }
    });
}

});


    // price-page(list)

    if ($('.litext').length){
        for (var i = 0; i < $('.litext').length; i++) {
            var e = $('.litext')[i];
            $(e).html('<p>'+$(e).text()+'</p>');
        };
        $('.litext').css('lineHeight', '80px');
    };

    // plagcheck

    if (('.plch-text').length) {
        $('textarea').attr('placeholder', 'INSERT TEXT FOR ANALYSIS (MINIMUM SYMBOL COUNT: 1000) ');
        $('.plag-submit').appendTo('.plch-box');
        $('.plch-box').insertAfter('.plch-text');
        $('#sym_count').appendTo('.plch-box');
        $('.plch-box').css({ opacity: 1, position:'relative' });
    }

});


$(document).ready(function(){
	$('.bxslider .tm_div:nth-child(1)').addClass('tm_div1');
	$('.bxslider .tm_div:nth-child(2)').addClass('tm_div2');
	$('.bxslider .tm_div:nth-child(3)').addClass('tm_div3');
	$('.bxslider .tm_div:nth-child(4)').addClass('tm_div4');
	$('.bxslider .tm_div:nth-child(5)').addClass('tm_div5');
	$('.bxslider .tm_div:nth-child(6)').addClass('tm_div6');
	$('.bxslider .tm_div:nth-child(7)').addClass('tm_div7');
	$('.bxslider .tm_div:nth-child(8)').addClass('tm_div8');
	$('.bxslider .tm_div1, .bxslider .tm_div2' ).wrapAll('<li></li>');
	$('.bxslider .tm_div3, .bxslider .tm_div4' ).wrapAll('<li></li>');
	$('.bxslider .tm_div5, .bxslider .tm_div6' ).wrapAll('<li></li>');
	$('.bxslider .tm_div7, .bxslider .tm_div8' ).wrapAll('<li></li>');
	$('.testimonials-home .bxslider').bxSlider({
		adaptiveHeight: true,
		controls: false,
		mode: 'fade',
		//auto: true,
		speed: 1000
		//moveSlides: 2
		//minSlides: 2
	});
	$('.sidebar .bxslider').bxSlider({
		controls: false,
		mode: 'fade',
		auto: true,
		speed: 4000
	});
});

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();


(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);


(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '121',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
